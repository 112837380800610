import React from "react";
import { Card, CardBody } from "reactstrap";
import door from "../../assets/images/door-to-door.png";
import ring from "../../assets/images/notification.png";
import mask from "../../assets/images/mask.png";
import call from "../../assets/images/no-call.png";
import dog from "../../assets/images/dog.png";
const Instructions = ({ Instructions }) => {
  console.log(Instructions, "instructionssss");
  return (
    <div>
      {Instructions?.bewareDog === false &&
      Instructions?.dontRingDoorBell === false &&
      Instructions?.wearMask === false &&
      Instructions?.additionalInstructions === "" ? (
        ""
      ) : (
        <Card className="mb-0">
          <h5 className="p-2 mt-2 ms-2">Instructions</h5>
          <CardBody className="border-top border-3">
            <div className="">
              {Instructions?.wearMask == true ? (
                <div className="d-flex align-items-center my-2">
                  {" "}
                  <img src={mask} />
                  <p className="mb-0 ms-2">Please wear mask</p>
                </div>
              ) : (
                ""
              )}
              {Instructions?.callMe == false ? (
                <div className="d-flex align-items-center my-2">
                  {" "}
                  <img src={call} />
                  <p className="mb-0 ms-2">Do not call me</p>
                </div>
              ) : (
                ""
              )}
              {Instructions?.dontRingDoorBell == true ? (
                <div className="d-flex align-items-center my-2">
                  <img src={ring} />
                  <p className="mb-0 ms-2">
                    Please don&apos;t ring the doorbell when you arrive
                  </p>
                </div>
              ) : (
                ""
              )}
              {Instructions?.withoutDoorMan == true ? (
                <div className="d-flex align-items-center my-2">
                  <img src={door} />
                  <p className="mb-0 ms-2">
                    Please leave the delivery with our doorman
                  </p>
                </div>
              ) : (
                ""
              )}{" "}
              {Instructions?.bewareDog == true ? (
                <div className="d-flex align-items-center my-2">
                  {" "}
                  <img src={dog} />
                  <p className="mb-0 ms-2">Please Beware of Dog </p>
                </div>
              ) : (
                ""
              )}
              {Instructions?.additionalInstructions && (
                <div className="mt-3">
                  <h6>Additional Comments by the customer : </h6>
                  <p>{Instructions?.additionalInstructions}</p>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default Instructions;